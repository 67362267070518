<template>
	<div style="max-width: 900rem; margin: auto; padding: 50rem 0">
		<div style="margin-bottom: 50rem">
			<el-button type="text" icon="el-icon-back" style="font-size: 26rem;color: #000" @click="() => this.$router.back()"></el-button>
			<span style="font-size: 26rem; margin-left: 30rem; font-weight: bold">个人中心</span>
		</div>
		<div class="from-box">
			<div class="from-box-item">
				<el-image
					style="width: 100rem; height: 100rem; border-radius: 50%; margin-bottom: 20rem; margin-top: 60rem"
					:src="image === '' ? imageUrl : image"
				>
				</el-image>
				<br />
				<el-button @click="dialogVisible = true" style="color: #666; font-weight: bold">上传头像</el-button>
			</div>
			<el-form ref="form" :model="form" :rules="rules" label-width="90rem" size="mini" @submit.native.prevent>
				<!-- <el-divider content-position="left"><h3>基础信息</h3></el-divider> -->
				<t-card
					style="border-top: 1rem solid #e8edff"
					title="基础信息"
					:title-style="{ padding: '5rem 40rem', background: '#E8EDFF', color: '#1C4EFD', borderRadius: '0 0 4rem 4rem' }"
				/>
				<br />
				<div style="background: #fff">
					<el-form-item label="姓名:">
						<span>{{ form.clerk_name }}</span>
					</el-form-item>
					<el-form-item label="性别:">
						<span>{{ form.gender === 2 ? '男' : form.gender === 1 ? '女' : '保密' }}</span>
						<!-- <el-select :popper-append-to-body="false" v-show="!show" v-model="form.gender" placeholder="请选择您的性别">
							<el-option v-for="item in genderArr" :key="item.value" :label="item.label" :value="item.value"> </el-option>
						</el-select> -->
					</el-form-item>
					<el-form-item label="出生日期:" prop="birthday">
						<span v-show="show">{{ form.birthday }}</span>
						<el-date-picker
							v-show="!show"
							value-format="yyyy-MM-dd"
							:picker-options="pickerOptions"
							v-model="form.birthday"
							type="date"
							placeholder="请选择您的生日"
						>
						</el-date-picker>
					</el-form-item>
					<el-form-item label="毕业院校:" prop="graduated_from">
						<span v-show="show">{{ form.graduated_from }}</span>
						<el-input v-show="!show" placeholder="请输入您的毕业院校" v-model="form.graduated_from" style="width: 220rem" clearable>
						</el-input>
					</el-form-item>
					<el-form-item label="最高学历:" prop="education">
						<span v-show="show">{{
							form.education == 7
								? '博士研究生'
								: form.education == 6
								? '硕士研究生'
								: form.education == 5
								? '本科'
								: form.education == 4
								? '专科'
								: form.education == 3
								? '高中'
								: form.education == 2
								? '初中'
								: form.education == 1
								? '小学'
								: ''
						}}</span>
						<el-select :popper-append-to-body="false" v-show="!show" v-model="form.education" placeholder="请选择您的学历">
							<el-option v-for="(item, index) in degreeArr" :key="index" :label="item" :value="Number(index)"> </el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="所学专业:" prop="major">
						<span v-show="show">{{ form.major }}</span>
						<el-input v-show="!show" placeholder="请输入您的专业" v-model="form.major" style="width: 220rem" clearable> </el-input>
					</el-form-item>
					<el-form-item label="联系电话:" prop="mobile">
						<span v-show="show">{{ form.mobile }}</span>
						<el-input maxlength="11" v-show="!show" placeholder="请输入您的电话" v-model="form.mobile" style="width: 220rem" clearable>
						</el-input>
					</el-form-item>
					<el-form-item label="企微邮箱:" prop="mailbox">
            <span v-show="show">{{ form.mailbox }}</span>
						<el-input maxlength="35" v-show="!show" placeholder="请输入您的企微邮箱" v-model="form.mailbox" style="width: 220px" clearable>
						</el-input>
					</el-form-item>
				</div>
				<br />
				<!-- <el-divider content-position="left"><h3>入职信息</h3></el-divider> -->
				<t-card
					style="border-top: 1rem solid #e8edff"
					title="入职信息"
					:title-style="{ padding: '5rem 40rem', background: '#E8EDFF', color: '#1C4EFD', borderRadius: '0 0 4rem 4rem' }"
				/>
				<br />
				<div style="background: #fff">
					<el-form-item label="教学学科:">
            <p v-if="form.fist_subject_text || form.subject_text">{{ form.fist_subject_text }}/{{ form.subject_text }}</p>
						<p v-else>-</p>
						<!-- <span v-show="show">{{ form.fist_subject_text }}/{{ form.subject_text }}</span> -->
						<!-- <el-select :popper-append-to-body="false" v-show="!show" v-model="form.subject" placeholder="请选择您的一级学科">
							<el-option v-for="item in subjectArrTo" :key="item.id" :label="item.subject_name" :value="item.id"> </el-option>
						</el-select> -->
						<!-- <el-cascader v-show="!show" v-model="form.subject" :options="subjectArrTo"></el-cascader> -->
					</el-form-item>
					<el-form-item label="入职时间:">
						<span>{{ form.entry_time }}</span>
					</el-form-item>
					<el-form-item label="是否在职:">
						<span>{{ form.clerk_status == 1 ? '在职' : form.clerk_status == 2 ? '离职' : '' }}</span>
					</el-form-item>
				</div>
				<br />
				<!-- <el-divider content-position="left"><h3>个人简介</h3></el-divider> -->
				<t-card
					style="border-top: 1rem solid #e8edff"
					title="个人简介"
					:title-style="{ padding: '5rem 40rem', background: '#E8EDFF', color: '#1C4EFD', borderRadius: '0 0 4rem 4rem' }"
				/>
				<br />
				<div style="background: #fff">
					<el-form-item label="优势标签:" prop="tags">
						<el-tag
							v-show="show"
							style="margin-right: 10rem"
							v-for="tag in form.tags"
							:disable-transitions="false"
							@close="handleClosea(tag)"
						>
							{{ tag }}
						</el-tag>
						<el-tag
							v-show="!show"
							style="margin-right: 10rem"
							v-for="tag in form.tags"
							closable
							:disable-transitions="false"
							@close="handleClosea(tag)"
						>
							{{ tag }}
						</el-tag>
						<el-input
							class="input-new-tag"
							v-if="inputVisible"
							v-model="inputValue"
							ref="saveTagInput"
							@keyup.enter.native="handleInputConfirm"
						>
						</el-input>
						<el-button v-show="!show" v-else class="button-new-tag" size="small" @click="showInput" icon="el-icon-plus" circle></el-button>
						<div v-show="!show" style="font-size: 12rem !important; color: #666666">按回车键保存</div>
					</el-form-item>
					<el-form-item label="个人简介:" prop="summary">
						<span v-show="show" style="word-break: break-all">{{ form.summary }}</span>
						<el-input v-show="!show" type="textarea" placeholder="请输入内容" v-model="form.summary" maxlength="300" show-word-limit>
						</el-input>
					</el-form-item>
					<el-form-item label="个人照片:" prop="img_URL">
						<el-image style="width: 200rem; height: 250rem" :src="form.img_URL === ''||form.img_URL==null ? imgURL_ : form.img_URL">
            </el-image><br />
						<el-input v-model="form.img_URL" v-show="false"></el-input>
						<el-upload
							class="upload-demo"
							:action="$_axios.defaults.baseURL + '/site/upload'"
							list-type="picture"
							:show-file-list="false"
							:multiple="false"
							v-show="!show"
							:headers="{ authorization: 'Bearer ' + $store.getters.token }"
							:on-success="onChange"
							:on-error="onChangea"
							:before-upload="beforeAvatarUpload"
							name="image"
						>
							<el-button>点击上传</el-button>
							<div slot="tip" class="el-upload__tip">仅支持上传jpg，png格式的图片，且图片大小不超过2M</div>
						</el-upload>
					</el-form-item>
					<el-form-item label="审核状态:">
						<span>{{ form.checked === 0 ? '待审核' : form.checked === 1 ? '审核通过' : '审核不通过' }}</span>
					</el-form-item>
					<el-form-item label="不通过原因:" v-show="form.checked == 2 ? true : false">
						<span>{{ form.failed_reason }}</span>
					</el-form-item>
				</div>
				<br />
				<el-form-item> </el-form-item>
			</el-form>
			<el-button style="background: #1b2286; color: #fff" v-if="show" @click="edit">编辑</el-button>
			<el-button type="primary" style="width: 120rem" v-if="!show" @click="save('form')">保存</el-button>
		</div>

		<!-- 弹出层-裁剪 -->
		<el-dialog title="编辑头像" :visible.sync="dialogVisible" :before-close="handleClose">
			<input
				ref="filElem"
				type="file"
				id="uploads"
				accept="image/png, image/jpeg, image/gif, image/jpg"
				@change="uploadImg($event, 1)"
				class="el-button hide"
				style="margin-bottom: 15rem"
				v-show="false"
			/>
			<el-row>
				<el-col :span="14">
					<!-- 裁剪 -->
					<vueCropper
						style="width: 100%; height: 600rem"
						ref="cropper"
						:img="attach.customaryUrl"
						:autoCrop="options.autoCrop"
						:fixedBox="options.fixedBox"
						:canMoveBox="options.canMoveBox"
						:autoCropWidth="options.autoCropWidth"
						:autoCropHeight="options.autoCropHeight"
						:centerBox="options.centerBox"
						@realTime="realTime"
					></vueCropper>
				</el-col>
				<el-col :span="4" style="color: #fff">1</el-col>
				<el-col :span="4" style="display: flex">
					<div style="padding-top: 200rem; line-height: 100rem">
						<el-button type="primary " size="medium" @click="confirm('blob')">保存头像</el-button>
						<br />
						<el-button type="info" size="medium" @click="clickUpload">上传头像</el-button>
					</div>
				</el-col>
			</el-row>
		</el-dialog>
	</div>
</template>
<script>
import imgURL from '../../assets/img/avatar_boy.png'
import imgURL_ from '../../assets/img/photo_boy.png'
import { getRoleList, getSubject, editRoleList, uploadBase64, avator } from './api'
import { mapGetters, mapMutations } from 'vuex'
import { fistSubject } from '@/api/account-management'
export default {
	data() {
		return {
			test: null,
			pickerOptions: {
				disabledDate(time) {
					return time.getTime() > Date.now() - 8.64e6 //如果没有后面的-8.64e6就是不可以选择今天的
				}
			},
			rules: {
				gender: [{ required: false, message: '请选择你的性别', trigger: 'change' }],
				graduated_from: [{ required: false, message: '请输入你的毕业院校', trigger: 'blur' }],
				education: [{ required: false, message: '请选择你的学历', trigger: 'change' }],
				major: [{ required: false, message: '请输入你的所学专业', trigger: 'blur' }],
				mobile: [{ required: false, message: '请输入你的手机号', trigger: 'blur' }],
				tags: [{ required: false, message: '请输入你的标签', trigger: 'blur' }],
				summary: [{ required: false, message: '请输入你的个人简介', trigger: 'blur' }],
				img_URL: [{ required: false, message: '请上传你的个人照片', trigger: 'change' }],
				mailbox: [{ required: false, message: '请输入你的企微邮箱', trigger: 'blur' }]
			},
			inputVisible: false,
			inputValue: '',
			image: '',
			imageUrl: imgURL,
			imgURL_: imgURL_,
			genderArr: [
				{
					value: 2,
					label: '男'
				},
				{
					value: 1,
					label: '女'
				},
				{
					value: 0,
					label: '保密'
				}
			],
			degreeArr: { 7: '博士研究生', 6: '硕士研究生', 5: '本科', 4: '专科', 3: '高中', 2: '初中', 1: '小学' },
			// subjectArrTo: [],
			options: {
				autoCrop: true, //默认生成截图框
				fixedBox: true, //固定截图框大小
				canMoveBox: false, //截图框不能拖动
				centerBox: false, //截图框被限制在图片里面
				autoCropWidth: 200, //截图框宽度
				autoCropHeight: 200 //截图框高度
			},
			previews: {}, //实时预览图数据
			attach: {
				//后端附件表
				id: '',
				userId: '',
				customaryUrl: '', //原图片路径
				laterUrl: '', //裁剪后图片路径  /static/logo.png
				attachType: 'photo' //附件类型
			},
			dialogVisible: false,
			form: {
				clerk_name: '',
				gender: '',
				birthday: '',
				graduated_from: '',
				education: '',
				major: '',
				mobile: '',
				// subject: '',
				// subject_text: '',
				entry_time: '',
				clerk_status: '',
				tags: [],
				degree: [],
				summary: '',
				avator: '',
				checked: '',
				failed_reason: '',
				img_URL: null,
				photo: '',
        mailbox: ''
			},
			show: true
		}
	},
	computed: {
		...mapGetters(['userInfo']),
		user_info() {
			return this.userInfo.userinfo
		}
	},
	methods: {
		...mapMutations(['setUserInfo']),
		onChange(file) {
			this.$forceUpdate()
			this.form.photo = file.data.avator
			this.form.img_URL = file.data.image_url
		},
		beforeAvatarUpload(file) {
			const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'
			const isLt2M = file.size / 1024 / 1024 < 2

			if (!isJPG) {
				this.$message.error('上传头像图片只能是 pjpeg或jpeg或jpg或png 格式!')
			}
			if (!isLt2M) {
				this.$message.error('上传头像图片大小不能超过 2MB!')
			}
			return isJPG && isLt2M
		},
		onChangea() {
			this.$methods.console.error('上传出错请重试')
		},

		handleClosea(tag) {
			this.form.tags.splice(this.form.tags.indexOf(tag), 1)
		},

		showInput() {
			if (this.form.tags.length < 6) {
				this.inputVisible = true
				this.$nextTick(_ => {
					this.$refs.saveTagInput.$refs.input.focus()
				})
			} else {
				this.$message.error('个人标签数量过多请先删除后在尝试')
			}
		},

		handleInputConfirm() {
			let inputValue = this.inputValue
			let reg = /^[\u4E00-\u9FA5]+$/
			if (reg.test(inputValue)) {
				if (inputValue.length <= 6) {
					this.form.tags.push(inputValue)
					this.inputVisible = false
					this.inputValue = ''
				} else {
					this.$message.error('一个标签内最多可编辑6个以内汉字')
					this.inputValue = ''
				}
			} else {
				this.$message.error('请输入中文')
			}
			// if (inputValue != '') {
			// 	this.inputVisible = false
			// 	this.inputValue = ''
			// } else {
			// 	this.inputVisible = false
			// }
		},
		handleClose() {
			this.dialogVisible = false
		},
		//实时预览
		realTime(data) {
			this.previews = data
		},
		//点击图片调取input
		clickUpload() {
			this.$refs.filElem.dispatchEvent(new MouseEvent('click'))
		},
		//选择本地图片
		uploadImg(e, num) {
			var file = e.target.files[0]
			if (file != undefined) {
				if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
					this.$message.error('图片类型必须是.gif,jpeg,jpg,png,bmp中的一种')
					return false
				}
				//fileReader 接口，用于异步读取文件数据
				var reader = new FileReader()
				reader.readAsDataURL(file) //重要 以dataURL形式读取文件
				reader.onload = e => {
					let data = e.target.result
					this.attach.customaryUrl = data
				}
			}
		},
		//确认截图,上传
		confirm() {
			this.$refs.cropper.getCropData(res => {
				uploadBase64({ image: res }).then(res => {
					if (res.data.error.errorCode == 0) {
						avator({ avator: res.data.data.avator })
						this.image = res.data.data.image_url
						this.form.avator = res.data.data.avator
						this.dialogVisible = false
						this.checked = 0
						let userinfo = this.userInfo
						// userinfo.userinfo.avator = res.data.data.image_url
						this.setUserInfo(userinfo)
					} else {
						this.$message.error('保存失败请稍后重试')
					}
				})
			})
		},
		edit() {
      if (this.form.education === 0){
        delete this.form.education
      }
			this.show = false
			window.scrollTo(0, 0)
			for (const key in this.rules) {
				this.rules[key][0].required = true
			}
		},
		save(formName) {
			this.$refs[formName].validate(valid => {
				this.inputVisible = false
				if (valid) {
					let {avator_text, photo_text, ...params } = this.form
					editRoleList(params)
						.then(res => {
							window.scrollTo(0, 0)
							this.$message.success('保存成功  ')
							this.show = !this.show
							this.form.checked = 0
							this.$forceUpdate()
							getRoleList().then(res => {
								if (res.data.data.first == 1) {
									this.form = Object.assign(this.form, res.data.data)
								} else {
									this.form = res.data.data
								}
								// this.form.subject = this.form.subject
								// this.form.subject[0] = this.form.subject[0] + ''
								// this.form.subject[1] = this.form.subject[1] + ''
								this.form.img_URL = res.data.data.photo_text
								this.image = res.data.data.avator_text

								this.$forceUpdate()
							})
						})
						.catch(() => {})
					for (const key in this.rules) {
						this.rules[key][0].required = false
					}
				} else {
					return false
				}
			})
		}
	},
	mounted() {},
	created() {
		// getSubject().then(res => {
		// 	this.subjectArrTo = JSON.parse(
		// 		JSON.stringify(res.data.data)
		// 			.replace(/id/g, 'value')
		// 			.replace(/subject_name/g, 'label')
		// 			.replace(/child/g, 'children')
		// 	)
		// })
		getRoleList().then(res => {
			// if (res.data.data.first == 1) {
			this.form = Object.assign(this.form, res.data.data)
			// this.form.subject = this.form.subject.toString()
			// } else {
			// 	// this.form = res.data.data
			//   Object.assign(this.form, res.data.data)
			// 	// this.form.subject = this.form.subject.toString()
			// }
			// this.form.subject = this.form.subject
			// this.form.subject[0] = this.form.subject[0] + ''
			// this.form.subject[1] = this.form.subject[1] + ''

			this.form.img_URL = res.data.data.photo_text
			this.image = res.data.data.avator_text
			this.$forceUpdate()
		})
	}
}
</script>

<style scoped lang="sass">
.from-box
  position: relative
  .from-box-item
    position: absolute
    right: 0
    text-align: center
    z-index: 100
    padding-right: 24rem

.el-dialog .el-dialog__body
  border-top: 1rem solid #dcdfe6
  border-bottom: 1rem solid #dcdfe6
  height: calc(100vh - 281rem)
  overflow-y: auto
  .el-tag + .el-tag
    margin-left: 10rem

  .button-new-tag
    margin-left: 10rem
    height: 32rem
    line-height: 30rem
    padding-top: 0
    padding-bottom: 0

  .input-new-tag
    width: 90rem
    margin-left: 10rem
    vertical-align: bottom
</style>
