import request from '@/utils/request'

//get 请求
/* 获取个人中心详情 */
export const getRoleList = () => {
  return request({
    method: 'get',
    url: '/site/info',
  })
}

/**
 * post
 * 编辑个人中心详情
 */
export const editRoleList = (data) => {
  return request({
    method: 'post',
    url: 'site/edit',
    data
  })
}

/**
 * post
 * 个人中心-个人性格照片
 */
export const uploadBinary = (data) => {
  return request({
    method: 'post',
    url: 'site/upload-binary',
    data
  })
}

/**
 * post
 * 个人中心 - 个人头像
 */
export const uploadBase64 = (data) => {
  return request({
    method: 'post',
    url: 'site/upload-base64',
    data
  })
}


/**
 * post
 * 个人中心 - 个人头像
 */
export const avator = (data) => {
  return request({
    method: 'post',
    url: 'site/avator',
    data
  })
}






/**
 * 请求方式---GET
 * 请求参数---无
 * 学科列表
 */
export const getSubject = () => {
  return request({
    method: 'get',
    url: 'site/subject',
  })
}